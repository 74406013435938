// 调课
<template>
  <div class="change_course_dialog">
    <CommonDialog @close="close" :showDialog="showDialog" :title="title" :width="width" :isCenter="true">
      <template v-slot:content>
        <div class="dialog_content">
          <!-- 内容视图 -->
          <div class="dv_table_view_content" v-if="showLoading == 1">
            <div class="margin_left_40 margin_top_10">
              <!-- 课程名称 -->
              <span>课程名称：{{ courseName }}</span>
            </div>
            <div class="margin_left_40 margin_top_30">
              <!-- 班级名称 -->
              <div>
                <div class="width_class_name">班级名称：{{ item.class_name }}</div>
                <span class="margin_left_40">老师：{{ item.teacher_name }}</span>
              </div>
            </div>

            <div class="margin_left_40 margin_top_30">
              <!-- 课程名称 -->
              <span
                >上课教室:
                <el-select class="item-width" v-model="classRoom" placeholder="请选择" size="small">
                  <el-option v-for="item in classRooms" :key="item.room_id" :label="item.room_name" :value="item.room_id"> </el-option>
                </el-select>
              </span>
            </div>
            <div class="margin_left_40 margin_top_30">
              <!-- 班级名称 -->
              <span
                >上课日期:
                <el-date-picker class="item-width" v-model="classDate" type="date" placeholder="选择日期" size="small" value-format="yyyy-MM-dd">
                </el-date-picker>
              </span>
              <span class="margin_left_40"
                >上课时段:
                <el-select class="item-width" v-model="timeLine" placeholder="请选择" size="small">
                  <el-option v-for="item in options" :key="item.time_id" :label="item.time_range" :value="item.time_id"> </el-option>
                </el-select>
              </span>
            </div>

            <!-- 底部 -->
            <div class="bom_page margin_bom_10">
              <!-- 底部的取消和保存按钮 -->
              <div class="bom">
                <!-- 保存 -->
                <el-button size="medium" class="bt_next" type="primary" :loading="loading" @click.stop="doSave">确&nbsp;&nbsp;定</el-button>
              </div>
            </div>
          </div>

          <!-- 加载视图 -->
          <!-- <Loading v-if="showLoading == -1"></Loading> -->
        </div>
      </template>
    </CommonDialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      //搜索内容
      studentText: "",
      //选中个数
      SelectLength: 0,
      //选中的数组
      multipleSelection: [],
      //显示加载布局的标识
      showLoading: 1,

      //表格的最大高度
      maxHeight: 0,

      //总的数据条数
      totalSize: 30,

      //条数选择的数据
      pageSizeArr: [6, 8, 10],

      //每页显示的条数
      pageSize: 10,

      //当前显示的页码
      currPage: 1,

      //表格数据
      tableData: [],
      //时段
      options: [],

      //教师
      classRooms: [],
      //上课时段
      timeLine: "",
      //日期
      classDate: "",
      //教室
      classRoom: "",
      //课程名称
      courseName: "",
    };
  },
  created() {},
  mounted() {
    this.httpData();

    this.initData();
  },
  methods: {
    initData() {
      console.log(this.item);
      this.item.subList.forEach((element, index) => {
        if (index == 0) {
          this.courseName = element.subName;
        } else {
          this.courseName += "," + element.subName;
        }
      });
    },
    /**
     *  调课列表信息接口
     */
    httpData() {
      let param = this.getHttpParams();
      param.type = 14;
      param.cid = this.item.class_id;
      this.$http
        .fetchPost(this.$api.CLASSMANAGER, param)
        .then((res) => {
          //显示内容视图

          let resulte = res.data;
          if (resulte.state == 200) {
            this.options = resulte.data.timeList;

            this.classRooms = resulte.data.roomList;
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          console.log("请求数据异常");
        });
    },

    //关闭的方法
    close() {
      this.$emit("close");
    },

    //确定
    doSave() {
      this.loading = true;

      if (this.isEmpty(this.classRoom)) {
        this.showWarnMsg("请选择上课教室");
        this.loading = false;
        return;
      }
      if (this.isEmpty(this.classDate)) {
        this.showWarnMsg("请选择上课日期");
        this.loading = false;
        return;
      }
      if (this.isEmpty(this.timeLine)) {
        this.showWarnMsg("请选择上课时段");
        this.loading = false;
        return;
      }

      let param = this.getHttpParams();
      param.type = 15;
      param.cid = this.item.class_id;
      param.planId = this.planId;
      param.timeId = this.timeLine;
      param.roomId = this.classRoom;
      param.date = this.classDate;

      this.$http
        .fetchPost(this.$api.CLASSMANAGER, param)
        .then((res) => {
          //显示内容视图
          let resulte = res.data;
          if (resulte.state == 200) {
            this.loading = false;
            this.showSuccessMsg("操作成功");
            this.$emit("close");
            this.$emit("reflash");
          } else if (resulte.state == 406) {
            this.loading = false;
            this.showWarnMsg("与课程" + resulte.data.class_name + "的" + resulte.data.time_range + "时段冲突");
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log("请求数据异常");
        });
    },
  },
  computed: {},
  components: {},

  props: ["showDialog", "title", "width", "item", "planId"],
};
</script>
<style lang="scss" scoped>
.change_course_dialog {
  .dialog_content {
    height: 50vh;
    margin: 10px 0;
    display: flex;
    align-items: center;

    //表格内容部分
    .dv_table_view_content {
      width: 97%;
      margin: 0 auto;
      height: 100%;
      .input_item {
        width: 188px;
      }
      .item-width {
        width: 188px;
      }
      .width_class_name {
        width: 250px;
        display: inline-block;
      }
      //底部的分页器部分
      .bom_page {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        margin-top: 0px;
        display: flex;

        height: 20px;

        .text {
          margin-left: 10%;
          margin-top: -12px;
        }
        .bom {
          position: absolute;
          height: 40px;
          bottom: 20px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .bt_cancel,
          .bt_next {
            width: 120px;
          }

          .bt_next {
            // margin-left: 100px;
          }
        }
      }
    }
  }
}
</style>

//日课程表
<template>
  <div class="day_lessons">
    <!-- 视图部分 -->
    <Layout :showLoading="showLoading">
      <template v-slot:content>
        <!-- 内容部分 -->
        <div class="day_lessons_details_content">
          <!-- 顶部操作栏 -->
          <div class="top_action">
            <!-- 上课日期 -->
            <div class="class_time">
              <span class="margin_right_10">上课日期</span>

              <!-- 日期选择 -->
              <el-date-picker
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                v-model="chooseTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份"
              >
              </el-date-picker>
            </div>

            <!-- 上课状态 -->
            <div class="margin_left_20">
              <el-select v-model="lessonState" placeholder="上课状态">
                <el-option v-for="(item, index) in curriculumType" :key="index" :label="item.stateName" :value="item.stateName"> </el-option>
              </el-select>
            </div>

            <!-- 全部教室 -->
            <div class="margin_left_20">
              <el-select v-model="classRoom" placeholder="全部教室">
                <el-option v-for="(item, index) in roomList" :key="index" :label="item.roomName" :value="item.roomName"> </el-option>
              </el-select>
            </div>

            <!-- 全部老师 -->
            <div class="margin_left_20">
              <el-select v-model="teacher" placeholder="全部老师">
                <el-option v-for="(item, index) in classPlanList" :key="index" :label="item.userName" :value="item.userName"> </el-option>
              </el-select>
            </div>

            <!-- 全部班级 -->
            <div class="margin_left_20">
              <el-select v-model="className" placeholder="全部班级">
                <el-option v-for="(item, index) in classInfoList" :key="index" :label="item.className" :value="item.className"> </el-option>
              </el-select>
            </div>

            <!-- 设置已上和未上的按钮 -->
            <div class="setting_action margin_left_20" v-if="lessonState === '未上课'">
              <!-- 设为已上 : 'search_bt_normal' com_bt_action class="search_bt_active bt_setting_up" -->
              <el-button type="primary" size="medium" :loading="showUpLoading" @click.stop="doSettinAction()"> 设为已上 </el-button>
            </div>

            <!-- 站位符 -->
            <div class="placeholder_view"></div>

            <!-- 导出 -->
            <el-button :loading="showExporLoading" type="primary" size="medium" @click.stop="doExportAction"> 导&nbsp;&nbsp;出</el-button>
          </div>

          <!-- 表格部分 -->
          <div class="dv_table_view_content">
            <el-table ref="table_d_l" style="width: 100%; margin: auto" border stripe :data="tableData" :max-height="maxHeight">
              <!-- 选中按钮 只有未上课的状态下的才显示选中按钮-->
              <el-table-column v-if="lessonState === '未上课' && tableData.length > 0" align="center" width="40">
                <template slot-scope="scope">
                  <span
                    @click.stop="doSelectAction(scope.row)"
                    :class="['common_box', 'iconfont', scope.row.selected ? 'icon-checkbox' : 'icon-CheckboxUnchecked']"
                  ></span>
                </template>
              </el-table-column>

              <!-- 上课日期/星期  -->
              <el-table-column prop="classDate" align="center" :show-overflow-tooltip="true" label="上课日期/星期" width="170"></el-table-column>

              <!-- 上课时段  width="140"  -->
              <el-table-column prop="timeNmae" align="center" label="上课时段" width="140"></el-table-column>

              <!-- 班级名称 -->
              <el-table-column prop="className" :show-overflow-tooltip="true" align="center" label="班级名称" min-width="3%"></el-table-column>

              <!-- 课程名称 -->
              <el-table-column prop="curriculumName" align="center" :show-overflow-tooltip="true" label="课程名称" min-width="3%"></el-table-column>

              <!-- 第几次课  -->
              <el-table-column prop="curriculumSum" align="center" :show-overflow-tooltip="true" label="第几次课" width="80"></el-table-column>

              <!-- 上课状态  -->
              <el-table-column prop="classState" align="center" label="上课状态" width="80"></el-table-column>

              <!-- 上课教室  -->
              <el-table-column prop="roomName" align="center" label="上课教室" width="100"></el-table-column>

              <!-- 上课老师  -->
              <el-table-column prop="teacherName" align="center" label="上课教师" width="100"></el-table-column>

              <!-- 已到  -->
              <el-table-column prop="arrived" align="center" label="已到" width="60"></el-table-column>

              <!-- 未到  -->
              <el-table-column prop="notArrived" align="center" label="未到" width="60"></el-table-column>

              <!-- 已评 -->
              <el-table-column prop="reviewed" align="center" label="已评" width="60"></el-table-column>

              <!--  操作 -->
              <el-table-column align="center" label="操作" width="120">
                <template slot-scope="scope">
                  <el-button
                    :title="scope.row.state == -1 ? tipMsg : ''"
                    :disabled="scope.row.state == -1"
                    @click="doLookAction(scope.row)"
                    type="text"
                    size="small"
                    >查看</el-button
                  >
                  <!-- 只有未上课的才显示调课 -->
                  <el-button v-if="scope.row.classState == '未上'" @click="changeCourse(scope.row)" type="text" size="small">调课</el-button>
                  <el-button v-if="scope.row.classState == '未上'" @click="cancelCourse(scope.row)" type="text" size="small">取消</el-button>
                </template>
              </el-table-column>
            </el-table>

            <!-- 顶部的分页器部分 -->
            <div class="bom_page margin_bom_20" v-if="tableData && tableData.length > 0">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currPage"
                :page-sizes="pageSizeArr"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalSize"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </template>
    </Layout>

    <!-- 调课 -->
    <ChangeCourse
      v-if="showDialog"
      @close="showDialog = false"
      title="调课"
      :showDialog="showDialog"
      :width="changeCourseWidth"
      :item="actionItem"
      :planId="planId"
      @reflash="httpData(1)"
    ></ChangeCourse>

    <!-- 取消课程的弹框 -->
    <RemoveCourseDialog
      ref="del_item"
      v-if="showDelDialog"
      :showDialog="showDelDialog"
      @close="showDelDialog = false"
      title="取消课程"
      :item="actionItem"
      @delItem="delItem"
    ></RemoveCourseDialog>
  </div>
</template>
<script>
//导出表格的操作
import { export_json_to_excel } from "../../../Excel/Export2Excel";

//调课
import ChangeCourse from "@/views/educationalcenter/classmanager/ChangeCourse";

//取消排课
import RemoveCourseDialog from "@/components/project/RemoveCourseDialog";

export default {
  name: "dayLessons",
  data() {
    return {
      //加载
      showLoading: -1,

      //是否允许查询
      isArrowSearch: false,

      //设置为已上的加载状态值
      showUpLoading: false,

      //是否允许点击导出
      isExcel: false,

      //上课状态
      lessonState: "上课状态",

      //上课状态数组
      curriculumType: [],

      //教室
      classRoom: "全部教室",

      //教室数组
      roomList: [],

      //老师
      teacher: "全部老师",

      //老师数组
      classPlanList: [],

      //班级名称,默认全部教室
      className: "全部班级",

      //班级数组
      classInfoList: [],

      //日期选择器的值
      chooseTime: "",

      //日期选择的配置项
      pickerOptions: {
        //设置禁用日期,2022年1月1日之前的不允许选择,最多选择到当前日期
        disabledDate(time) {
          let dates = new Date("2021-12-31");
          // 当前日期的限定   time.getTime() >= new Date().getTime()
          return time.getTime() <= dates.getTime();
        },
      },

      //表格的最大高度
      maxHeight: 0,

      //表格的宽度
      tabWidth: 0,

      //总的数据条数
      totalSize: 0,

      //条数选择的数据
      pageSizeArr: [6, 8, 10],

      //每页显示的条数
      pageSize: 10,

      //当前显示的页码
      currPage: 1,

      //选中的数组
      multipleSelection: [],

      //用于记录选中的数据数组
      recordSelection: [],

      //表格数据
      tableData: [],

      //搜索的起始日期
      start_date: "",
      end_date: "",

      //上课状态 -1取消  0未上 1已上  2全部 默认全部
      state: "2",

      //所选教室  0为全部 默认
      roomId: "0",

      //所选教师 0 为全部 默认
      teacherId: "0",

      //所选班级 0为全部 默认
      classId: "0",

      //导出表格的表头
      tHeaderArr: ["上课日期/星期", "上课时段", "班级名称", "课程名称", "第几次课", "上课状态", "上课教室", "上课教师", "已到", "未到", "已评"],

      //导出表格的字段
      tBodyValue: [
        "classDate",
        "timeNmae",
        "className",
        "curriculumName",
        "curriculumSum",
        "classState",
        "roomName",
        "teacherName",
        "arrived",
        "notArrived",
        "reviewed",
      ],

      //导出时的加载框
      showExporLoading: false,

      //取消课程
      showDelDialog: false,

      //调课
      showDialog: false,

      //排课ID
      planId: "",

      //调课
      changeCourseWidth: "700px",

      //标题
      delTitle: "取消课程",

      //查看按钮禁用时的提示信息
      tipMsg: "班级已被删除,无法进行查看操作",
    };
  },
  created() {
    //初始化表格的最大高度
    this.initData();

    //请求页面数据
    this.httpData(1);
  },
  methods: {
    //初始化表格最大高度
    initData() {
      let clientH = document.documentElement.clientHeight;
      this.maxHeight = clientH - 76 - 56 - 100 - 30;
      let clientW = window.screen.width;
      this.tableWidth = clientW - 200 - 30;
    },

    //点击导出的操作
    doExportAction() {
      this.showExporLoading = true;
      this.httpData(2);

      //导出的时候请求一下服务器
      // this.httpMsg();
    },

    //导出表格的方法
    exportExcel(serverData) {
      if (this.isEmpty(serverData)) {
        this.showWarnMsg("暂无数据,无需进行导出操作");
        this.showExporLoading = false;
        return;
      }
      require.ensure([], () => {
        //定义表头
        const tHeader = this.tHeaderArr;
        //定义表格数据的可想的属性key值
        const filterVal = this.tBodyValue;
        //把要导出的数据tableData存到list
        // const list = this.multipleSelection;
        const data = this.formatJson(filterVal, serverData);
        //导出操作最后一个是表名字
        export_json_to_excel(tHeader, data, "日课程表");
        setTimeout(() => {
          this.showExporLoading = false;
        }, 2000);
      });
    },

    //格式化json的方法
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },

    //设为已上的点击事件
    doSettinAction() {
      if (this.recordSelection.length == 0) {
        this.showWarnMsg("未选中任何课表条目");
        return;
      }
      this.showUpLoading = true;
      let param = this.getHttpParams();
      param.type = 3;
      param.id = this.getRecordIds();
      this.$http.fetchPost(this.$api.LESSONMANAGER, param).then((res) => {
        let result = res.data;

        setTimeout(() => {
          this.showUpLoading = false;
        }, 400);

        if (result.state == 200) {
          //操作成功 重新刷新页面数据
          //清空记录数据
          this.clearRecordArr();
          this.currPage = 1;
          this.httpData(1);
        } else {
          this.showErrorMsg(this.getMesage(res.data.state) + ",操作失败");
        }
      });
    },

    //每页显示的条数变化时触发
    handleSizeChange(val) {
      this.pageSize = val;
      // console.log("当前页显示的条数是:", this.pageSize);
      //切换页码
      this.currPage = 1;
      //重新刷新数据
      this.httpData(1);
    },

    //页面改变时触发
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currPage = val;
      //重新刷新数据
      this.httpData(1);
    },

    //清空记录数组的方法
    clearRecordArr() {
      this.recordSelection.splice(0, this.recordSelection.length);
    },

    //查看的操作
    doLookAction(item) {
      //跳转到班级管理详情界面
      this.$router.push({
        path: "/Home/ClassDetail",
        query: {
          item: JSON.stringify({ class_id: item.classId }),
        },
      });
    },

    /**
     * 请求数据
     * 参数
     * type=2
     * startTime 2022-05-03
     * endTime 2022-05-31
     * state 0
     * roomId 0
     * teacherId 0
     * classId 0
     * page 1
     * pagecount 100
     * curriculumType 1查询 2导出
     */
    httpData(curriculumType) {
      let param = this.getHttpParams();
      param.type = 2;
      param.curriculumType = curriculumType;
      param.page = this.currPage;
      param.pagecount = this.pageSize;
      if (!this.isEmpty(this.start_date)) {
        param.startTime = this.start_date;
      }
      if (!this.isEmpty(this.end_date)) {
        param.endTime = this.end_date;
      }
      param.state = this.state;
      param.roomId = this.roomId;
      param.teacherId = this.teacherId;
      param.classId = this.classId;
      param.userId = this.getUserInfo().id;

      this.$http
        .fetchPost(this.$api.LESSONMANAGER, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let result = res.data;
          // console.log("请求到的数据是:", result);

          //将表格滚动到顶部
          this.$nextTick(() => {
            this.$refs.table_d_l.bodyWrapper.scrollTop = 0;
          });

          if (result.state == 200) {
            if (curriculumType == 1) {
              //查询
              //上课状态
              this.curriculumType = result.data.curriculumType;
              //教室
              this.roomList = result.data.roomList;
              //老师
              this.classPlanList = result.data.classPlanList;
              //班级
              this.classInfoList = result.data.classInfoList;

              //获取列表数据
              this.totalSize = result.data.curriculumIndexPage.totalCount;

              //先清空一下表格数据
              this.tableData.splice(0, this.tableData.length);
            }

            //获取服务器的列表数据
            let serveData = result.data.curriculumIndexPage.curriculumIndexDataList;

            if (!this.isEmpty(serveData) && serveData.length > 0) {
              if (curriculumType == 1) {
                //查询
                this.tableData = serveData;
                this.tableData.forEach((item) => {
                  if (this.isEmpty(item.curriculumName)) {
                    //如果课程名称没有就显示-
                    item.curriculumName = "-";
                  }

                  if (this.isEmpty(item.curriculumSum)) {
                    //如果第几次课没有就显示-
                    item.curriculumSum = "-";
                  }

                  //配置默认是否选中
                  if (this.lessonState == "未上课") {
                    //只有在未上课的时候在去配置选中状态
                    if (this.hasRecord(item)) {
                      //当前条目有别记录
                      this.$set(item, "selected", true);
                    } else {
                      this.$set(item, "selected", false);
                    }
                  }
                });
              } else {
                //导出
                serveData.forEach((item) => {
                  if (this.isEmpty(item.curriculumName)) {
                    //如果课程名称没有就显示-
                    item.curriculumName = "-";
                  }

                  if (this.isEmpty(item.curriculumSum)) {
                    //如果第几次课没有就显示-
                    item.curriculumSum = "-";
                  }
                });

                //进行导出操作
                this.exportExcel(serveData);
              }
            } else {
              if (curriculumType == 2) {
                this.exportExcel(serveData);
              }
            }
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },

    //判断列表条目是否被记录
    hasRecord(item) {
      let res = false;
      for (let i = 0; i < this.recordSelection.length; i++) {
        if (this.recordSelection[i].id === item.id) {
          res = true;
          break;
        }
      }
      return res;
    },

    //选中或者取消的事件
    doSelectAction(item) {
      //将状态取反
      item.selected = !item.selected;

      //在同步一下存储状态
      if (item.selected) {
        //选中,添加到保存的数组
        this.recordSelection.push(item);
      } else {
        //取消的时候将当前元素从记录的数组中删除
        this.recordSelection.splice(
          this.recordSelection.findIndex((obj) => obj.id === item.id),
          1
        );
      }
      // console.log("操作后的记录数组是:", this.recordSelection);
    },

    //获取记录的所有拼接id
    getRecordIds() {
      let resultArr = this.recordSelection.map((item) => {
        return item.id;
      });
      return resultArr.join("-");
    },

    //导出的时候请求服务器
    httpMsg() {
      let param = this.getHttpParams();
      param.type = 16;
      this.$http.fetchPost(this.$api.LESSONMANAGER, param).then((res) => {
        //只用请求服务器,不需要做任何操作
      });
    },

    //调课
    changeCourse(item) {
      this.actionItem = {
        class_name: item.className,
        teacher_name: item.teacherName,
        class_id: item.classId,
        subList: [
          {
            subName: item.curriculumName,
          },
        ],
      };
      this.planId = item.id;
      this.showDialog = true;
    },
    //取消课程
    cancelCourse(item) {
      console.log("操作的对象是:", item);
      this.actionItem = {
        class_date: item.classDate.split("/")[0],
        weekDay: item.classDate.split("/")[1],
        time_range: item.timeNmae,
        cid: item.classId,
        planId: item.id,
      };
      this.showDelDialog = true;
    },

    //取消课程
    delItem() {
      console.log("操作对象是:", this.actionItem);
      let param = this.getHttpParams();
      param.type = 16;
      param.cid = this.actionItem.cid;
      param.planId = this.actionItem.planId;
      this.$http
        .fetchPost(this.$api.CLASSMANAGER, param)
        .then((res) => {
          //显示内容视图
          let resulte = res.data;
          if (resulte.state == 200) {
            this.showSuccessMsg("操作成功");
            this.httpData(1);
            this.showDelDialog = false;
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },
  },

  watch: {
    //监听时间选择
    //选择时间的监听
    chooseTime(newValue) {
      if (!this.isEmpty(newValue)) {
        this.start_date = this.chooseTime[0];
        this.end_date = this.chooseTime[1];
        // console.log("选择时间是:", this.start_date, this.end_date);
        //判断一下选择的时间间隔,最小1天,最大12个月
        if (!this.isRightTimeRange(this.start_date, this.end_date)) {
          this.showWarnMsg("选择的时间间隔必须大于1天");
          return;
        }

        //判断一下选择的时间间隔是否超过12个月
        if (this.isOverTimeRange(this.start_date, this.end_date)) {
          this.showWarnMsg("选择的时间间隔最大为12个月");
          return;
        }
      } else {
        this.start_date = "";
        this.end_date = "";
      }
      //还原页码重新请求数据
      this.clearRecordArr();
      this.currPage = 1;
      this.httpData(1);
    },

    //监听上课状态
    lessonState(newValue) {
      if (!this.isEmpty(newValue)) {
        let item = this.curriculumType.find((item) => {
          return item.stateName == newValue;
        });
        this.state = item.state;
        //请求页面数据
        this.clearRecordArr();
        this.currPage = 1;
        this.httpData(1);
      }
    },

    //监听上课教室
    classRoom(newValue) {
      if (!this.isEmpty(newValue)) {
        let item = this.roomList.find((item) => {
          return item.roomName == newValue;
        });
        this.roomId = item.roomId;
        //请求页面数据
        this.currPage = 1;
        this.httpData(1);
      }
    },

    //监听老师
    teacher(newValue) {
      if (!this.isEmpty(newValue)) {
        let item = this.classPlanList.find((item) => {
          return item.userName == newValue;
        });
        this.teacherId = item.teacherId;
        //请求页面数据
        this.currPage = 1;
        this.httpData(1);
      }
    },

    //监听班级
    className(newValue) {
      if (!this.isEmpty(newValue)) {
        let item = this.classInfoList.find((item) => {
          return item.className == newValue;
        });
        this.classId = item.classId;
        //请求页面数据
        this.currPage = 1;
        this.httpData(1);
      }
    },
  },
  computed: {},
  components: { ChangeCourse, RemoveCourseDialog },
};
</script>
<style lang="scss" scoped>
.day_lessons {
  margin: 0 14px;
  height: calc(100vh - 78px - 56px);
  .day_lessons_details_content {
    display: flex;
    flex-direction: column;

    //顶部操作栏
    .top_action {
      height: 50px;
      display: flex;
      align-items: center;

      .class_time {
        display: flex;
        align-items: center;

        ::v-deep .el-input__inner {
          height: 32px;
          line-height: 32px;
          width: 260px;
        }

        ::v-deep .el-range-separator {
          line-height: 24px;
          margin-right: 6px;
          margin-left: 2px;
        }

        ::v-deep .el-range__icon {
          line-height: 30px;
        }
      }

      .commit {
        height: 32px;
        width: 90px;
        background: #409eff;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    //设置已上,未上的按钮
    .setting_action {
      height: 50px;
      display: flex;
      align-items: center;

      .bt_setting_up {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #409eff;
        color: white;
      }
    }

    //表格内容部分
    .dv_table_view_content {
      flex: 1;
      margin-top: 16px;
      //底部的分页器部分
      .bom_page {
        position: absolute;
        bottom: 0;
        left: 0;
        margin-top: 18px;
        display: flex;
        align-items: center;
        height: 40px;
        justify-content: center;

        ::v-deep .el-pagination__editor.el-input {
          width: 50px !important;
        }
      }

      .common_box {
        cursor: pointer;
        font-size: 18px;
        color: #409eff;
      }
    }

    ::v-deep .el-input__inner {
      height: 32px;
      line-height: 32px;
      padding-left: 6px;
    }

    ::v-deep .el-input__icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ::v-deep .el-input {
      width: 140px;
    }

    ::v-deep .el-input__suffix {
      right: 0px;
    }
  }
}
</style>

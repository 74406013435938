//取消课程的弹框
<template>
  <div class="del_item_dialog">
    <CommonDialog @close="close" :showDialog="showDialog" :title="title" :width="width" :item="item">
      <template v-slot:content>
        <div class="del_item_dialog_content">
          <!-- 提示的内容部分 -->
          <span>
            <span class="show_msg">确定取消该次({{ item.class_date }}/{{ item.weekDay }}{{ item.time_range }})的上课计划吗？</span>
            <span class="show_msg_info">点击确认后，该次排课取消，同时家长和老师都会接收课程取消提醒</span>
          </span>

          <!-- 底部的取消和确定按钮 -->
          <div class="bom_action">
            <!-- 取消 -->
            <div class="cancel" @click.stop="close"><span>取</span><span style="margin-left: 16px">消</span></div>
            <!-- 确定 -->
            <el-button :loading="showCommitLoading" :class="['commit']" @click.stop="doCommitAction">
              <span>确</span><span style="margin-left: 16px">定</span>
            </el-button>
          </div>
        </div>
      </template>
    </CommonDialog>
  </div>
</template>
<script>
//引入通用Dialog对话框
import CommonDialog from "../../components/CommonDialog";

export default {
  data() {
    return {
      //宽度
      width: this.dialogWidth,

      //是否显示确定按钮加载框的标识
      showCommitLoading: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    //关闭弹框
    close() {
      //触发父组件的关闭方法
      this.$emit("close");
    },

    //确定的操作
    doCommitAction() {
      this.showCommitLoading = true;
      this.$emit("delItem");
    },
  },
  computed: {},
  components: {
    CommonDialog,
  },
  props: ["showDialog", "title", "item"],
};
</script>
<style lang="scss" scoped>
.del_item_dialog {
  .del_item_dialog_content {
    padding: 0 30px;
    display: flex;
    justify-content: center;
    color: black;
    font-size: 14px;
    flex-direction: column;
    .show_msg {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
    }
    .show_msg_info {
      margin-top: 6px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    //底部的操作按钮
    .bom_action {
      height: 48px;
      display: flex;
      justify-content: center;

      .cancel,
      .commit {
        width: 120px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
      }

      .cancel {
        background: #dcdcdc;
        margin-right: 30px;
        color: black;
      }

      .commit {
        background: #169bd5;
        color: white;
      }
    }
  }
}
</style>
